<template>
  <div>
    <v-dialog
      v-model="open"
      fullscreen
      scrollable
      transition="scale-transition"
      persistent
    >
      <v-card flat>
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <span class="font-weight-bold">
                {{ $t("e_test.etest_result") }}
              </span>
            </v-col>
            <v-col cols="6">
              <div style="position: absolute; right: 25px;">
                <v-icon :disabled="table.loading" @click="closeDialog"
                  >mdi-close</v-icon
                >
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider class="my-1" />
              <v-row no-gutters>
                <v-card
                  :class="!darkmode || 'dark-mode'"
                  class="rounded-lg pa-1"
                  flat
                  width="100%"
                >
                  <div class="d-flex align-center">
                    <div class="d-inline-block pl-2 align-self-end">
                      <v-select
                        v-model="modelTest"
                        :items="dataTable.dd_remedy"
                        :label="$t('e_test.choose_test')"
                        item-text="title"
                        item-value="etest_class"
                        hide-details
                        outlined
                        dense
                      />
                    </div>
                    <v-spacer />
                    <div class="d-inline-block divider px-10">
                      <div class="font-weight-light">
                        {{ $t("app.name") }}
                      </div>
                      <div class="font-weight-bold">
                        {{ dataTable.header.name }}
                      </div>
                    </div>
                    <div class="d-inline-block divider px-10">
                      <div class="font-weight-light">
                        {{ $t("app.subjects") }}
                      </div>
                      <div class="font-weight-bold">
                        {{ dataTable.header.lesson_name }}
                      </div>
                    </div>
                    <div class="d-inline-block px-10">
                      <div class="font-weight-light">
                        {{ $t("e_test.total_result") }}
                      </div>
                      <div class="font-weight-bold">
                        {{ dataTable.header.score }}
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-data-table
            :headers="tableHeaders"
            :items="dataTable.body.data"
            :page.sync="table.page"
            :items-per-page="table.limit"
            :loading="table.loading"
            single-expand
            show-expand
            item-key="id"
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">More info about {{ item.name }}</td>
            </template>
            <template #item.points="{ item }">
              <v-edit-dialog
                :return-value.sync="item.points"
                large
                persistent
                @save="savePoint(item)"
              >
                <!-- is_corrected (1 === diperiksa || 0 === belum diperiksa) -->
                <v-btn
                  v-if="
                    item.is_corrected === 0 && item.type === 'esay'
                      ? true
                      : false
                  "
                  :disabled="table.loading"
                  color="warning"
                  text
                  class="caption"
                >
                  <span v-html="$t('e_test.not_checked_yet_dialog')" />
                </v-btn>
                <v-btn v-else :disabled="table.loading" color="primary" text>
                  {{ item.points }}
                </v-btn>
                <template v-slot:input>
                  <v-text-field
                    v-model="item.points"
                    :rules="[
                      v => !!v || $t('app.required'),
                      v =>
                        Number(v) <= Number(item.max_point) ||
                        $t('e_test.msg_rule_max_score')
                    ]"
                    type="number"
                    single-line
                  />
                </template>
              </v-edit-dialog>
            </template>
            <template #item.number="{item}">
              {{ dataTable.body.data.indexOf(item) + 1 }}
            </template>
            <template #item.result="{item}">
              <v-btn
                v-if="item.type === 'pg' && item.result != null"
                :class="item.result ? 'gradient-primary' : 'gradient-error'"
                dark
                class="caption"
                depressed
                small
                style="pointer-events: none;"
              >
                <v-icon small class="mr-2">{{
                  item.result ? "mdi-check" : "mdi-close"
                }}</v-icon>
                {{
                  item.result ? $t("app.correct") : $t("app.incorrect")
                }}</v-btn
              >
            </template>
            <template #item.question="{item}">
              <span v-html="truncateHtml(item.question, 50)" />
            </template>
            <template #item.student_answer="{item}">
              <span v-html="truncateHtml(item.student_answer, 50)" />
            </template>
            <template #item.answer_key="{item}">
              <div v-for="(data, index) in answerKey(item)" :key="index">
                <span v-html="truncateHtml(data)" />
              </div>
            </template>
            <!-- <template #item.discussion="{item}">
              {{ truncateHtml(item.discussion) }}
            </template> -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="py-5 pl-16">
                <!-- start question -->
                <div
                  class="question"
                  v-html="item.question"
                  @click="viewImage($event)"
                />
                <v-row v-if="item.question_file[0]">
                  <v-col v-if="item.question_file.length === 1">
                    <v-img
                      v-if="validateImage(item.question_file[0])"
                      :src="item.question_file[0]"
                      style="cursor: pointer"
                      max-height="200px"
                      max-width="300px"
                      @click="
                        {
                          urlFile = item.question_file[0];
                          viewFile = true;
                        }
                      "
                    />
                    <v-chip
                      v-else
                      color="primary"
                      @click="
                        {
                          urlFile = item.question_file[0];
                          viewFile = true;
                        }
                      "
                    >
                      {{ item.question_file[0].replace(/^.*[\\/]/, "") }}
                    </v-chip>
                  </v-col>
                  <div
                    v-else
                    v-for="file in item.question_file"
                    :key="file"
                    class="ma-2"
                  >
                    <v-chip
                      @click="
                        {
                          urlFile = file;
                          viewFile = true;
                        }
                      "
                      color="primary"
                    >
                      {{ file.replace(/^.*[\\/]/, "") }}
                    </v-chip>
                  </div>
                </v-row>
                <!-- end question -->

                <!-- start multiple choice -->
                <v-row>
                  <v-col
                    v-for="data in item.pg"
                    :key="data.id"
                    cols="12"
                    sm="6"
                    class="my-2"
                  >
                    <div
                      :class="!data.key || 'font-weight-bold'"
                      class="d-flex flex-row"
                    >
                      <span class="mr-2">{{ data.code }}.</span>
                      <span
                        v-html="data.answer"
                        class="answer"
                        @click="viewImage($event)"
                      />
                    </div>
                    <v-row v-if="data.answer_file[0]">
                      <v-col v-if="data.answer_file.length === 1">
                        <v-img
                          v-if="validateImage(data.answer_file[0])"
                          :src="data.answer_file[0]"
                          style="cursor: pointer"
                          max-height="200px"
                          max-width="300px"
                          @click="
                            {
                              urlFile = data.answer_file[0];
                              viewFile = true;
                            }
                          "
                        />
                        <v-chip
                          v-else
                          color="primary"
                          @click="
                            {
                              urlFile = data.answer_file[0];
                              viewFile = true;
                            }
                          "
                        >
                          {{ data.answer_file[0].replace(/^.*[\\/]/, "") }}
                        </v-chip>
                      </v-col>
                      <div
                        v-else
                        v-for="file in data.answer_file"
                        :key="file"
                        class="ma-2"
                      >
                        <v-chip
                          color="primary"
                          @click="
                            {
                              urlFile = file;
                              viewFile = true;
                            }
                          "
                        >
                          {{ file.replace(/^.*[\\/]/, "") }}
                        </v-chip>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- end multiple choice -->

                <!-- start essay and key answer essay -->
                <div v-if="item.esay.key && item.type === 'esay'">
                  <v-divider class="mt-3" />
                  <div class="font-weight-bold my-3">
                    {{ $t("e_test.answer_key") }}
                  </div>
                  <span v-html="item.esay.key"></span>
                  <v-row v-if="item.esay.key_file[0]">
                    <v-col v-if="item.esay.key_file.length === 1">
                      <v-img
                        v-if="validateImage(item.esay.key_file[0])"
                        :src="item.esay.key_file[0]"
                        style="cursor: pointer"
                        max-height="200px"
                        max-width="300px"
                        @click="
                          {
                            urlFile = item.esay.key_file[0];
                            viewFile = true;
                          }
                        "
                      />
                      <v-chip
                        v-else
                        color="primary"
                        @click="
                          {
                            urlFile = item.esay.key_file[0];
                            viewFile = true;
                          }
                        "
                      >
                        {{ item.esay.key_file[0].replace(/^.*[\\/]/, "") }}
                      </v-chip>
                    </v-col>
                    <div
                      v-else
                      v-for="file in item.esay.key_file"
                      :key="file"
                      class="ma-2"
                    >
                      <v-chip
                        @click="
                          {
                            urlFile = file;
                            viewFile = true;
                          }
                        "
                        color="primary"
                      >
                        {{ file.replace(/^.*[\\/]/, "") }}
                      </v-chip>
                    </div>
                  </v-row>
                </div>
                <!-- start essay and key answer essay -->

                <!-- start discuss -->
                <v-divider class="my-3" />
                <div class="font-weight-bold mb-3">
                  {{ $t("e_test.explain") }}
                </div>
                <div>
                  <span v-html="item.discussion" />
                </div>
                <!-- start discuss -->

                <!-- start student answer -->
                <v-divider class="mt-3" />
                <div class="font-weight-bold my-3">
                  {{ $t("e_test.answer_student_file") }}
                </div>
                <div v-if="showStudentAnswerFile(item.student_answer_file)">
                  <div class="d-flex d-flex-row">
                    <div
                      v-for="(file, index) in item.student_answer_file"
                      :key="index"
                      cols="12"
                      class="ma-1"
                    >
                      <v-chip
                        v-if="validateUrl(file)"
                        color="primary"
                        class="caption"
                        @click="
                          {
                            urlFile = file;
                            viewFile = true;
                          }
                        "
                      >
                        {{ file.replace(/^.*[\\\/]/, "") }}
                      </v-chip>
                    </div>
                  </div>
                </div>
                <!-- end student answer -->

                <v-divider class="mt-3" />
                <div class="font-weight-bold my-3">
                  {{ $t("e_test.student_answers") }}
                </div>
                <div v-html="item.student_answer" />
              </td>
            </template>
          </v-data-table>
          <Pagination
            :length="dataTable.body.last_page"
            :total="dataTable.body.total"
            :current-page="table.page"
            :limit="table.limit"
            :handler="paginateChange"
          />
        </v-card-text>
        <v-divider class="my-1" />
        <v-card-actions>
          <v-row no-gutters justify="end">
            <!-- <v-btn
              :disabled="table.loading"
              outlined
              class="mr-2"
              color="primary"
              ><v-icon class="mr-2">mdi-printer</v-icon> Print</v-btn
            > -->
            <v-spacer />
            <v-btn
              class="gradient-primary"
              :disabled="table.loading"
              depressed
              dark
              @click="closeDialog"
            >
              {{ $t("app.done") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ViewImage
      :viewFile="viewFile"
      :urlFile="urlFile"
      @close="viewFile = false"
    />
  </div>
</template>

<script>
import { listScoreCorrection, updatePoint } from "@/api/admin/academic/eTest";
import store from "@/store";
import truncate from "truncate-html";
import { isExternal } from "@/utils/validate";
import { isImageFile } from "@/utils/validate";

export default {
  props: {
    open: Boolean,
    dataRequest: Object
  },
  watch: {
    open(value) {
      if (value) {
        this.request.student = this.dataRequest.student;
        this.request.etest_class = this.dataRequest.etest_class;
        this.getListScore();
      }
    },
    modelTest(value) {
      this.request.etest_class = value;
      this.getListScore();
    }
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    ViewImage: () => import("../../components/ViewImage")
  },
  data() {
    return {
      viewFile: false,
      urlFile: "",
      modelTest: 0,
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false
      },
      tableHeaders: [
        {
          text: "#",
          value: "number"
        },
        {
          text: this.$i18n.t("e_test.question"),
          value: "question"
        },
        {
          text: this.$i18n.t("e_test.student_answers"),
          value: "student_answer"
        },
        {
          text: this.$i18n.t("e_test.answer_key"),
          value: "answer_key"
        },
        // {
        //   text: this.$i18n.t("e_test.explanation"),
        //   value: "discussion"
        // },
        {
          text: this.$i18n.t("e_test.question_type"),
          value: "type_text"
        },
        {
          text: this.$i18n.t("e_test.score"),
          value: "points",
          align: "center"
        },
        {
          text: this.$i18n.t("e_test.max_score"),
          value: "max_point"
        },
        {
          text: this.$i18n.t("app.result"),
          value: "result"
        },
        { text: "", value: "data-table-expand" }
      ],
      dataTable: {
        header: {},
        body: {
          data: []
        }
      },
      request: {
        student: 0,
        etest_class: 0
      },
      disableButton: false
    };
  },
  methods: {
    viewImage(event) {
      if (event.target.src) {
        this.viewFile = true;
        this.urlFile = event.target.src;
      }
    },
    validateImage: name => isImageFile(name),
    showStudentAnswerFile(file) {
      if (Array.isArray(file) && file[0] != "") return true;
      else false;
    },
    answerKey(item) {
      let result = [];
      if (item.type === "pg") {
        result = item.pg.filter(pg => pg.key).map(pg => pg.answer);
      } else {
        result = [item.esay.key];
      }
      return result;
    },
    validateUrl: url => isExternal(url),
    truncateHtml: (html, length = 20) =>
      truncate(html, length, { stripTags: true }),
    async savePoint(item) {
      const body = { id: item.id, new_points: item.points };
      // validate point
      if (Number(item.points) <= Number(item.max_point)) {
        try {
          const response = await updatePoint(body);
          if (response.data.code) {
            this.snackBar(true, response.data.message);
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
      } else {
        this.snackBar(false, this.$i18n.t("e_test.msg_rule_max_score"));
      }
      this.getListScore();
    },
    paginateChange(page, limit) {
      this.table.limit = limit;
      this.table.page = page;
      this.getListScore();
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    closeDialog() {
      this.request = {
        student: 0,
        etest_class: 0
      };
      this.table = {
        limit: 10,
        page: 1,
        search: "",
        loading: false
      };
      this.dataTable = {
        header: {},
        body: {
          data: []
        }
      };
      this.$emit("close");
    },
    async getListScore() {
      this.table.loading = true;
      try {
        const response = await listScoreCorrection({
          ...this.request,
          ...this.table
        });
        if (response.data.code) {
          this.modelTest = response.data.data.dd_remedy.find(
            item => item.selected
          ).etest_class;
          this.dataTable = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.table.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dark-mode {
  background: #121212;
  border: 10px solid #424242;
}
.divider {
  border-right: 1px solid #e2e2e2;
}
</style>
